import React from 'react';

interface Props {
  if: boolean;
  loading?: boolean;
  loadingComponent?: React.ReactElement;
}

/**
 * 
 * Renders the children when a condition is true. Displays nothing if the condition is false.
 * 
 */
function Render(props: React.PropsWithChildren<Props>) {
  if (props.loading) {
    return props.loadingComponent;
  }
  
  if (props.if) {
      return <>{props.children}</>;
  }

  return null;
}

export default Render;