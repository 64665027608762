import React from 'react';
import buttonStyles from '../../styles/buttons.module.css';
import { Card, CardActions, CardContent, CardMedia, CircularProgress, Tooltip } from '@mui/material';

interface Props {
  addProductToCart: (product_id: number, qty: string) => void;
  addProductToCartMeta: {
    loading: boolean;
    success: boolean;
    failure: boolean;
    reset: () => void;
  }
  data: {
    product_id: number;
    file_small: string;
    product_name: string;
    product_link: string;
    product_sales_price: string;
    product_list_price: string;
    qty_available: number;
  }
  discountLevel: string;
}

function ProductCard(props: Props) {

  const [qty, setQty] = React.useState('1');
  const [productBeingAdded, setProductBeingAdded] = React.useState(0);
  
  React.useEffect(() => {
    if (props.addProductToCartMeta.success) {
      setTimeout(() => {
        props.addProductToCartMeta.reset();
        setProductBeingAdded(0)
      }, 2000);
    }
  }, [props.addProductToCartMeta.success]);

  function handleQtyChange(e) {
    const value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');

    setQty(value);
  }

  function addProductToCart(product) {
    if (qty && qty !== '' && qty !== '0') {
      props.addProductToCart(product.product_id, qty);
      setProductBeingAdded(product.product_id)
    } else {
      window.alert('Cannot add a product with no quantity');
    }
  }
  
  return (
    <Card sx={{ width: 140, height: 275, position: 'relative' }} variant='outlined'>
      <a href={props.data.product_link}>
        <CardMedia
          component='img'
          height='120'
          sx={{ padding: "0em 1em 0 1em", objectFit: "contain" }} 
          image={props.data.file_small}
          alt={props.data.product_name}
        />
      </a>
      <CardContent style={{ padding: '0px 10px' }}>
        {props.data.product_name.length > 40 ?
          <Tooltip title={props.data.product_name} enterDelay={700}>
            <p>
              <a href={props.data.product_link}>{props.data.product_name.slice(0, 39) + ' . . .'}</a>
            </p>
          </Tooltip>
        :
          <p>
            <a href={props.data.product_link}>{props.data.product_name}</a>
          </p>
        }
        <p>
          ${props.discountLevel == 'product_list_price' ? props.data.product_sales_price : props.data[props.discountLevel]}
        </p>
        <p style={{ textDecoration: 'line-through', fontSize: 11, color: 'darkGrey' }}>
          {parseFloat(props.data.product_sales_price) < parseFloat(props.data.product_list_price) && 'List Price: $' + props.data.product_list_price}
        </p>
        <p style={{ fontSize: 12, fontWeight: 600 }}>
          {props.data.qty_available > 0 ? 'In stock!' : 'Out of stock'}
        </p>
      </CardContent>
      <CardActions style={{ padding: 8, bottom: 0, position: 'absolute', justifyContent: 'space-between', alignItems: 'center' }}>
        <input type='text' value={qty} onChange={handleQtyChange} style={{ width: 18, marginBottom: 3, padding: '3px 3px' }} />
        {props.addProductToCartMeta.success && productBeingAdded == props.data.product_id ?
          <button 
            className={buttonStyles.successButton} 
            style={{ margin: 0, width: 92 }}
            onClick={() => {}}
            disabled={props.addProductToCartMeta.loading}
          >
            Added &#10004;
          </button>
        :
          <button 
            className={buttonStyles.submitButton} 
            style={{ margin: 0, width: 92 }}
            onClick={() => addProductToCart(props.data)}
            disabled={props.addProductToCartMeta.loading}
          >
            {(props.addProductToCartMeta.loading && productBeingAdded == props.data.product_id) ? <CircularProgress size={15} sx={{ color: 'white' }} /> : 'Add to Cart'}
          </button>
        }
      </CardActions>
    </Card>
  );
}

export default ProductCard;