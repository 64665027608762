import React from 'react';
import Link from 'next/link';
import ProductCard from '../ProductCard';
import styles from './CartHistory.module.css';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardMedia, Skeleton } from '@mui/material';
import Render from '../Render';

interface Props {
  addProductToCartMeta: { loading: boolean; success: boolean; failure: boolean; reset: () => void; };
  addProductToCart: (product_id: number, qty: string) => void;
  data: any[];
  meta: {
    failure: boolean;
    loading: boolean;
    message: string;
    reset: () => void;
    success: boolean;
  };
  screenWidth: number;
  discountLevel: string;
  hideCartLink?: boolean
}

function CartHistory(props: Props) {
  /**
   * This component is currently written to display a maximum of 5 parts at a time. 
   * That can be changed by changing the default state values here and the values we increment/decrement by in the showNextGroupOfCartHistoryItems function
   **/
  const [numberOfProducts, setNumberOfProducts] = React.useState(5);
  const [cartHistoryViewRange, setCartHistoryViewRange] = React.useState([0, 0 + numberOfProducts]);
  const [productsInCart, setProductsInCart] = React.useState([]);
  const [filteredCartHistory, setFilteredCartHistory] = React.useState([]);
  
  React.useEffect(() => {
    const cartProducts = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)cart\s*\=\s*([^;]*).*$)|^.*$/, "$1")).split(',');

    setProductsInCart(cartProducts.map(product => {
      return product.split('|')[0];
    }));
  }, [props.addProductToCartMeta.success]);
  
  React.useEffect(() => {
    if (productsInCart.length !== 0 && props.data.length !== 0) {
      setFilteredCartHistory(props.data.filter(part => {
        if (!productsInCart.includes(part.product_id.toString())) {
          return part;
        }
      }));
    }
  }, [productsInCart, props.data]);
  
  React.useEffect(() => {
    if (props.screenWidth >= 1200) {
      setNumberOfProducts(5);
    } else if (props.screenWidth >= 535) {
      setNumberOfProducts(3);
    } else {
      setNumberOfProducts(2);
    }
  },[props.screenWidth]);

  React.useEffect(() => {
    setCartHistoryViewRange([0, 0 + numberOfProducts - 1]); // Minus one to account for array indexing starting at 0
  }, [numberOfProducts]);

  function showNextGroupOfCartHistoryItems(direction: string) {
    if (direction == 'up' && cartHistoryViewRange[1] !== filteredCartHistory.length - 1) {
      setCartHistoryViewRange([cartHistoryViewRange[0] + numberOfProducts, cartHistoryViewRange[1] + numberOfProducts]);
    } else if (direction == 'down' && cartHistoryViewRange[0] !== 0) {
      setCartHistoryViewRange([cartHistoryViewRange[0] - numberOfProducts, cartHistoryViewRange[1] - numberOfProducts]);
    }

    if (direction == 'up' && cartHistoryViewRange[1] >= filteredCartHistory.length - 1) { // At the last "page" trying to go forward. Go back to begining
      setCartHistoryViewRange([0, 0 + numberOfProducts - 1]);
    } else if (direction == 'down' && cartHistoryViewRange[0] <= 0) { // At the first "page" trying to go back. Go to the last "page"
      setCartHistoryViewRange([filteredCartHistory.length - 5, filteredCartHistory.length - 5 + numberOfProducts - 1]);
    }
  }
  
  return (
    <div style={{ width: '100%' }}>
      <div className={styles.title_container}>
        <h2 className={styles.title}>Items Previously In Your Cart</h2>
        {!props.hideCartLink && <Link href='cart'><a className={styles.cart_link}>Go to Cart &gt;</a></Link>}
      </div>
      <Render if={props.meta.loading || (props.meta.success && filteredCartHistory.length !== 0)}>
        <div className={styles.cart_history}>
          <div className={styles.arrow_container_left}>
            <Render if={filteredCartHistory.length > 5}>
              <ArrowBack sx={{ cursor: 'pointer' }} onClick={() => showNextGroupOfCartHistoryItems('down')} />
            </Render>
          </div>
          <div className={styles.products_container}>
            <Render if={props.meta.success}>
              {filteredCartHistory.map((part, index) => {
                if (index >= cartHistoryViewRange[0] && index <= cartHistoryViewRange[1]) {
                  return (
                    <ProductCard
                      key={part.product_id}
                      data={part}
                      discountLevel={props.discountLevel}
                      addProductToCart={props.addProductToCart}  
                      addProductToCartMeta={props.addProductToCartMeta}
                    />
                  );
                }
              })}  
            </Render>
            <Render if={props.meta.loading}>
              <ProductListSkeleton numberOfProducts={numberOfProducts} />
            </Render>
          </div>
          <div className={styles.arrow_container_right}>
            <Render if={filteredCartHistory.length > 5}>
              <ArrowForward sx={{ cursor: 'pointer' }} onClick={() => showNextGroupOfCartHistoryItems('up')} />
            </Render>
          </div>
        </div>
      </Render>
      <Render if={props.meta.success && filteredCartHistory.length == 0}>
        <div className={styles.cart_history_no_products}>
          <p style={{ marginLeft: 10 }}>Parts removed from your cart without checking out with be shown here</p>
        </div>
      </Render>
    </div>
  );
}

export default CartHistory;

function ProductListSkeleton(props) {
  const [array, setArray] = React.useState([]);

  React.useEffect(() => {
    buildArray();
  }, [props.numberOfProducts]);

  function buildArray() {
    let counter = 1;
    let newArray = [];

    while (counter <= props.numberOfProducts) {
      newArray.push(counter);

      counter++;
    }

    setArray(newArray);
  }

  return (
    <>
      {array.map(i => {
        return (
          <Card key={i} sx={{ width: 150, height: 275, position: 'relative' }}>
            <CardMedia
              component='img'
              height='120'
              sx={{ backgroundColor: '#ddd' }}
            />
            <CardContent>
              <Skeleton width={100} />
              <Skeleton width={50} />
            </CardContent>
            <CardActions style={{ padding: 8, bottom: 0, position: 'absolute', justifyContent: 'space-between', alignItems: 'center' }}>
              <Skeleton height={25} width={35} variant='rectangular' />
              <Skeleton height={25} width={90} variant='rectangular' />
            </CardActions>
          </Card>
        );
      })}
    </>
  );
}