import { prefix, ajax } from './common';

export async function searchAccounts(keywords, page = 1, sort_by, sortDirection) {
  return await ajax({
    url: prefix + '/accounts/search',
    data: {
      page,
      q: keywords,
      sort_by,
      sortDirection
    },
  });
}

export async function getAccount(account_id: number) {
  return await ajax({
    url: prefix + '/accounts/' + account_id,
  });
}

export async function getCartHistory(account_id: number) {
  return await ajax({
    url: prefix + '/accounts/' + account_id + '/cart-history',
  });
}

export async function getDiscountLevel(account_id: number) {
  return await ajax({
    url: prefix + '/accounts/' + account_id + '/discount-level',
  });
}

export async function getAccountOrders(account_id: number) {
  return await ajax({
    url: prefix + '/accounts/' + account_id + '/orders',
  });
}

export async function getAccountsEquipment(account_id: number, filter = { brand: '', subtype: '' }, page = 1) {
  return await ajax({
    url: prefix + '/accounts/' + account_id + '/equipment',
    data: {
      paginate: true,
      brand: filter.brand,
      subtype: filter.subtype,
      page: page
    }
  });
}

export async function getAccountsEquipmentBrands(account_id: number) {
  return await ajax({
    url: prefix + '/accounts/' + account_id + '/equipment/brands',
  });
}

export async function getAccountsEquipmentSubTypes(account_id: number, brand = '') {
  return await ajax({
    url: prefix + '/accounts/' + account_id + '/equipment/subtypes',
    data: {
      brand: brand
    }
  });
}